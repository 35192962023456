import {Component, OnInit} from '@angular/core';
import {DatabaseService} from '../../services/database.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../../models/user';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public surveyUser$: BehaviorSubject<User>;

  constructor(private db: DatabaseService) {
    this.surveyUser$ = this.db.surveyUser$;
  }

  ngOnInit() {
  }

}
