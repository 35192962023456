import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../models/user';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  public fireAuthUser: any; // FireAuth user object. Contains email
  public user$: Observable<User>;
  // public user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public surveyUser: User;
  public surveyUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public loggedInUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private surveyUserSubscription: Subscription;

  constructor(
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private auth: AuthService
  ) {
    // Subscribe to changes in the login state
    // and set the localstorage('user') to the logged in user object or null
    this.ngFireAuth.authState.subscribe(fireAuthUser => {
      if (fireAuthUser) {
        this.fireAuthUser = fireAuthUser;
        localStorage.setItem('user', JSON.stringify(this.fireAuthUser));
        this.isAuthenticated$.next(true);
        this.getUser(fireAuthUser.email).subscribe(user => {
          this.loggedInUser$.next(user);
          console.log('FIREAUTH STATE CHANGE');
          this.surveyUser$.next(user);
        });
        this.auth.actAsUser(fireAuthUser.email);
      } else {
        localStorage.setItem('user', null);
        this.isAuthenticated$.next(false);
      }
    });

    this.auth.actsAsUser$.subscribe( email => {
      if (email) {
        this.loginAsSurveyUser(email);
        this.getUser(email).subscribe(user => {
            if (user.email !== this.fireAuthUser.email) {
              this.surveyUser$.next(user);
            }
        });
      }
    });

    this.surveyUser$.subscribe(user => {
      this.surveyUser = user;
    });
  }

// Login in with email/password
  signIn(email, password): Promise<any> {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password);
  }

  // Register user with email/password
  registerUser(email, password) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
  }

  // Email verification when new user register
  async sendVerificationMail() {
    (await this.ngFireAuth.currentUser).sendEmailVerification().then(() => {
        this.router.navigate(['verify-email']);
    });
  }

  // Recover password
  passwordRecover(passwordResetEmail) {
    return this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email has been sent, please check your inbox.');
      }).catch((error) => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  saveSurvey(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${this.surveyUser.email}`);
    console.log('Saving', this.surveyUser.email);
    return userRef.set(user, {
      merge: true
    });
  }

  loginAsSurveyUser(email: string) {
    console.log('Log in as surveyuser');
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${email}`);
    if (this.surveyUserSubscription) {
      console.log('Unsubscribe snapshot');
      this.surveyUserSubscription.unsubscribe();
    }
    this.surveyUserSubscription = userRef.snapshotChanges().subscribe(data => {
      this.surveyUser = data.payload.data() as User;
      this.surveyUser.email = data.payload.id;
    });
  }

  getUser(email: string): Observable<User> {
    return this.getUserDataFromEmail$(email);
  }

  private getUserDataFromEmail$(email: string): Observable<User> {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${email}`);
    return userRef.get().pipe(
      map(doc => Object.assign({email}, doc.data()) )
    );
  }

  // Sign-out
  signOut() {
    return this.ngFireAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigateByUrl('/', { replaceUrl: true });
    });
  }

}
