// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyA-XEjJ9ss5wm1wfSvx_NuuKqTBGtFBz7Q',
    authDomain: 'hotstore-18f7e.firebaseapp.com',
    projectId: 'hotstore-18f7e',
    storageBucket: 'hotstore-18f7e.appspot.com',
    messagingSenderId: '364500823418',
    appId: '1:364500823418:web:9b6e7e1c40fdaf1f15bac3',
    measurementId: 'G-PZ2WDG369D'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
