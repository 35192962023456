import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Manage authorisation by storing the current user in .actsAsUser so it can be retrieved by
 */
export class AuthService {
  public actsAsUser$: BehaviorSubject<string>;

  constructor() {
    this.actsAsUser$ = new BehaviorSubject<string>(null);
  }

  public actAsUser(email: string) {
    console.log('AUTH: Act as user');
    this.actsAsUser$.next(email);
  }
}
